import React from "react";
import classes from "./Footer.module.scss";
import Button from "../../common/button/Button";
import CircleFillSvg from "../../custom/CircleFillSvg/CircleFillSvg";
import { Link } from "react-router-dom";

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
  return (
    <div className={classes.body}>
      <div className={classes.content}>
        <div className={classes.top}>
          {/*logo*/}
          <div className={classes.logos}>
            <i className={classes.logo}></i>
            <Link to="https://www.facebook.com/PolfarmexSA">
              <CircleFillSvg
                fillPercentage={100}
                iconName="Facebook"
                className={classes.logoMedia}
              ></CircleFillSvg>
            </Link>
            <Link to="https://www.linkedin.com/company/polfarmex-s-a-/">
              <CircleFillSvg
                fillPercentage={100}
                iconName="In"
                className={classes.logoMedia}
              ></CircleFillSvg>
            </Link>
          </div>
          {/*nav*/}
          <div className={classes.nav}>
            <Button
              url="https://polfarmex.pl/o-nas/"
              outside
              simple
              className={classes.navItem}
            >
              O firmie
            </Button>
            <Button
              url="https://polfarmex.pl/polityka-prywatnosci/"
              outside
              simple
              className={classes.navItem}
            >
              Polityka Prywatności
            </Button>
            {/*<Button
              url="https://www.polfarmex.pl/?pd=nagrody"
              outside
              simple
              className={classes.navItem}
            >
              Nagrody
            </Button>*/}
            <Button
              url="https://polfarmex.pl/csr/"
              outside
              simple
              className={classes.navItem}
            >
              Odpowiedzialność społeczna
            </Button>
            <Button
              url="https://polfarmex.pl/misja-i-wartosci/"
              outside
              simple
              className={classes.navItem}
            >
              Wizja i misja
            </Button>
          </div>
        </div>
        {/*info*/}
        <div className={classes.info}>
          <div className={classes.infoItemList}>
            <div className={classes.infoItem}>
              POLFARMEX S.A. | polfarmex.pl
            </div>
            <div className={classes.infoItem}>
              <p>
                <span>ul. Józefów 9,</span> <span>99-300 Kutno, Polska</span>
              </p>
              <p>
                <span>tel.: +48 24 357 44 44</span>
                <span>e-mail: polfarmex@polfarmex.pl</span>
              </p>
            </div>
          </div>
          <div className={classes.infoItemList}>
            <div className={classes.infoItem}>Dział Marketingu</div>
            <div className={classes.infoItem}>
              <p>
                <span>ul. Poleczki 35,</span>{" "}
                <span>02-822 Warszawa, Polska</span>
              </p>
              <p>
                <span>tel. +48 22 320 06 00</span>
                <span>e-mail: recepcjamarketing@polfarmex.pl</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
