import React, { useEffect, useRef, useState } from "react";
import classes from "./Navigation.module.scss";
import Button from "../../common/button/Button";
import { NavLink, useLocation } from "react-router-dom";
import IconLoader from "../../custom/Icons/IconLoader";
import classNames from "classnames";

interface NavigationProps {}

const Navigation: React.FC<NavigationProps> = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setIsActive(false);
  }, [location]);

  useEffect(() => {
    if (isActive) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    document.body.classList.toggle("hamburger-open", isActive);

    return () => document.body.classList.remove("hamburger-open");
  }, [isActive]);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (!e.target) {
        return;
      }
      if (!ref?.current?.contains(e.target as Node)) {
        setIsActive(false);
      }
    };

    const timeoutId = setTimeout(() => {
      document.addEventListener("click", handleOutsideClick, false);
    }, 0);
    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener("click", handleOutsideClick, false);
    };
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className={classes.nav} ref={ref}>
      <div className={classes.hamburger} onClick={() => setIsActive(!isActive)}>
        <IconLoader name={isActive ? "Hamburger" : "Hamburger"}></IconLoader>
      </div>
      <NavLink to="/" className={classes.logo}></NavLink>
      <div
        className={classNames(
          classes.hamburgerContent,
          isActive && classes.active
        )}
      >
        <Button url="/about" simple>
          O leku
        </Button>
        <Button url="/sleep-is-important" simple>
          Dlaczego sen jest ważny
        </Button>
        <Button url="/analysis" simple>
          Badanie "Bezsenność"
        </Button>
        <Button url="/knowledge" simple>
          Dla farmaceuty
        </Button>

        <Button
          outside
          download
          url={"/nodisen_ulotka.pdf"}
          className={classes.leaflet}
          fill
        >
          Pobierz Ulotkę <IconLoader name="Download"></IconLoader>
        </Button>
      </div>
      <Button
        url="https://www.polfarmex.pl/?pd=kontakt"
        outside
        className={classes.contactUs}
      >
        {windowWidth >= 992 ? "Skontaktuj się z nami" : "Kontakt"}
      </Button>
    </nav>
  );
};

export default Navigation;
