import React from "react";
import classNames from "classnames";
import classes from "./Button.module.scss";
import { NavLink } from "react-router-dom";

interface ButtonProps {
  className?: string;
  children: React.ReactNode;
  url?: string;
  simple?: boolean;
  outside?: boolean;
  fill?: boolean;
  download?: boolean;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  url,
  children,
  className,
  simple,
  outside,
  fill,
  download,
  onClick,
}) => {
  const Component: React.ElementType = url ? (outside ? "a" : NavLink) : "div";

  let componentProps = {};

  if (url) {
    componentProps = outside ? { href: url } : { to: url };
  }
  if (download) {
    componentProps = { ...componentProps, download };
  }

  return (
    <Component
      onClick={onClick ? onClick : null}
      {...componentProps}
      className={classNames(
        classes.body,
        className,
        !simple && !fill && classes.natural,
        fill && classes.fill
      )}
    >
      <span className={classes.text}>{children}</span>
    </Component>
  );
};

export default Button;
