// src/routes/Routes.tsx
import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  HomePage,
  SleepIsImportant,
  AnalysisPage,
  AboutPage,
  KnowledgePage,
} from "../pages/LazyPages";
import { KnowledgeSubpage } from "../pages/subpages/LazySubpages";
import Layout from "../components/common/Layout";
import { NavigationProvider } from "../contexts/NavigationContext";

const AppRoutes = () => {
  return (
    <Router>
      <NavigationProvider>
        <Layout>
          <Suspense fallback={""}>
            <Routes>
              <Route path="/">
                <Route index element={<HomePage />} />
                <Route
                  path="sleep-is-important"
                  element={<SleepIsImportant />}
                />
                <Route path="analysis" element={<AnalysisPage />} />
                <Route path="about" element={<AboutPage />} />
                <Route path="knowledge">
                  <Route index element={<KnowledgePage />} />
                  <Route path=":article" element={<KnowledgeSubpage />} />
                </Route>
              </Route>
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </Suspense>
        </Layout>
      </NavigationProvider>
    </Router>
  );
};

export default AppRoutes;
