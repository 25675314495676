import React from "react";
import classNames from "classnames";
import classes from "./CircleFillSvg.module.scss";
import IconLoader, { IconLoaderType } from "../Icons/IconLoader";

interface CircleFillSvgProps {
  className?: string;
  fillPercentage: number;
  iconName: IconLoaderType;
  onClick?: () => void;
}

const CircleFillSvg: React.FC<CircleFillSvgProps> = ({
  className,
  fillPercentage,
  iconName,
  onClick,
}) => {
  const calculateStrokeProperties = (fill: number) => {
    const radius = 22;
    const circumference = 2 * Math.PI * radius;
    const fillLength = (fill / 100) * circumference;
    const emptyLength = circumference - fillLength;
    const strokeDasharray = `${fillLength} ${emptyLength}`;
    const strokeDashoffset = "34.5"; // This can be adjusted if needed

    return {
      strokeDasharray: strokeDasharray,
      strokeDashoffset: strokeDashoffset,
    };
  };

  const { strokeDasharray, strokeDashoffset } =
    calculateStrokeProperties(fillPercentage);

  return (
    <div className={classNames(classes.wrapper, className)} onClick={onClick}>
      <IconLoader name={iconName}></IconLoader>
      <svg
        width="100"
        height="100"
        viewBox="0 0 48 48"
        xmlns="http://www.w3.org/2000/svg"
        className={classes.content}
      >
        <defs>
          <linearGradient id="borderGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0" stopColor="rgba(64, 85, 171, 1)" />
            <stop offset="1" stopColor="rgba(0, 177, 235, 1)" />
          </linearGradient>
        </defs>
        <circle
          cx="24"
          cy="24"
          r="22"
          fill="none"
          stroke="rgba(255, 255, 255, 0.1)"
          strokeWidth="2"
        ></circle>
        {fillPercentage !== 0 && (
          <circle
            cx="24"
            cy="24"
            r="22"
            fill="none"
            stroke="url(#borderGradient)"
            strokeWidth="2"
            strokeDasharray={strokeDasharray}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap={fillPercentage === 100 ? "inherit" : "round"}
          ></circle>
        )}
      </svg>
    </div>
  );
};

export default CircleFillSvg;
