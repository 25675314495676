import React, { Suspense } from "react";
import {
  ArrowLeft,
  ArrowRight,
  CalendarClock,
  Download,
  Facebook,
  FaceSad,
  Hamburger,
  In,
  LayerGroup,
  Rotate,
  TriangleExclamation,
  TriangleExclamationSolid,
  Xmark,
  Bed,
  Ceneo,
  FaceSmileRelaxed,
  Gdziepolek,
  Ktomalekpl,
  MoonStars,
  WineBottle,
  PersonRunning,
  MoonCloud,
  Mobile,
  FaceSleeping,
  Burger,
  BedFront,
  AlarmClock,
  Timer,
  Check,
  Pill,
  Age18,
  Loader,
} from "./LazyIcons";

const iconMapping = {
  ArrowLeft,
  ArrowRight,
  CalendarClock,
  Download,
  Facebook,
  FaceSad,
  Hamburger,
  In,
  LayerGroup,
  Rotate,
  TriangleExclamation,
  TriangleExclamationSolid,
  Xmark,
  Bed,
  Ceneo,
  FaceSmileRelaxed,
  Gdziepolek,
  Ktomalekpl,
  MoonStars,
  WineBottle,
  PersonRunning,
  MoonCloud,
  Mobile,
  FaceSleeping,
  Burger,
  BedFront,
  AlarmClock,
  Timer,
  Check,
  Pill,
  Age18,
  Loader,
};

export type IconLoaderType = keyof typeof iconMapping;

interface IconLoaderProps {
  name: IconLoaderType;
}

const IconLoader: React.FC<IconLoaderProps> = ({ name }) => {
  const IconComponent = iconMapping[name];

  if (!IconComponent) {
    return null;
  }

  return (
    <Suspense fallback={<i data-icon={name} />}>
      <i data-icon={name}>
        <IconComponent />
      </i>
    </Suspense>
  );
};

export default IconLoader;
