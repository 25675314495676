import React, { lazy } from "react";

export const ArrowLeft = lazy(() => import("../../../assets/icons/ArrowLeft"));
export const ArrowRight = lazy(
  () => import("../../../assets/icons/ArrowRight")
);
export const CalendarClock = lazy(
  () => import("../../../assets/icons/CalendarClock")
);
export const Download = lazy(() => import("../../../assets/icons/Download"));
export const Facebook = lazy(() => import("../../../assets/icons/Facebook"));
export const FaceSad = lazy(() => import("../../../assets/icons/FaceSad"));
export const Hamburger = lazy(() => import("../../../assets/icons/Hamburger"));
export const In = lazy(() => import("../../../assets/icons/In"));
export const LayerGroup = lazy(
  () => import("../../../assets/icons/LayerGroup")
);
export const Rotate = lazy(() => import("../../../assets/icons/Rotate"));
export const TriangleExclamation = lazy(
  () => import("../../../assets/icons/TriangleExclamation")
);
export const TriangleExclamationSolid = lazy(
  () => import("../../../assets/icons/TriangleExclamationSolid")
);

export const Xmark = lazy(() => import("../../../assets/icons/Xmark"));

export const Bed = lazy(() => import("../../../assets/icons/Bed"));
export const Ceneo = lazy(() => import("../../../assets/icons/ceneo"));
export const FaceSmileRelaxed = lazy(
  () => import("../../../assets/icons/FaceSmileRelaxed")
);
export const Gdziepolek = lazy(
  () => import("../../../assets/icons/gdziepolek")
);
export const Ktomalekpl = lazy(
  () => import("../../../assets/icons/ktomalekpl")
);
export const MoonStars = lazy(() => import("../../../assets/icons/MoonStars"));

export const WineBottle = lazy(
  () => import("../../../assets/icons/WineBottle")
);
export const PersonRunning = lazy(
  () => import("../../../assets/icons/PersonRunning")
);
export const MoonCloud = lazy(() => import("../../../assets/icons/MoonCloud"));
export const Mobile = lazy(() => import("../../../assets/icons/Mobile"));
export const FaceSleeping = lazy(
  () => import("../../../assets/icons/FaceSleeping")
);
export const Burger = lazy(() => import("../../../assets/icons/Burger"));
export const BedFront = lazy(() => import("../../../assets/icons/BedFront"));
export const AlarmClock = lazy(
  () => import("../../../assets/icons/AlarmClock")
);

export const Timer = lazy(() => import("../../../assets/icons/Timer"));
export const Check = lazy(() => import("../../../assets/icons/Check"));
export const Pill = lazy(() => import("../../../assets/icons/Pill"));
export const Age18 = lazy(() => import("../../../assets/icons/18+"));
export const Loader = lazy(() => import("../../../assets/icons/Loader"));
