import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const NavigationContext = createContext({
  historyCountStart: 0,
  currentPage: "home",
  setHistoryCountStart: (historyCountStart: number) => {},
});

export const NavigationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const location = useLocation();
  const [historyCountStart, setHistoryCountStart] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<string>("home");

  useEffect(() => {
    setHistoryCountStart(window.history.length);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentPage(location.pathname.split("/")[1] || "home");
  }, [location]);

  return (
    <NavigationContext.Provider
      value={{
        historyCountStart,
        setHistoryCountStart,
        currentPage,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => useContext(NavigationContext);
