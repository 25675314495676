// src/components/common/Layout.tsx
import React from "react";
import Footer from "../layout/Footer/Footer";
import Navigation from "../layout/Navigation/Navigation";
import { useNavigationContext } from "../../contexts/NavigationContext";
import classes from "./Layout.module.scss";

interface LayoutProps {
  children: any;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { currentPage } = useNavigationContext();

  return (
    <>
      <main className={classes.body} data-path={currentPage}>
        <Navigation />
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
